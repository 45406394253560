import React from 'react';
import { FaUpload, FaDownload, FaMagic } from 'react-icons/fa';

const HowToEnhanceSection = () => {
  return (
    <section className="py-12 md:py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-5xl font-extrabold text-center text-gray-900 mb-8">
        How to Enhance Your Photos
        </h2>
        <p className="text-lg md:text-xl text-center text-gray-600 mb-12">
        Follow these simple steps to transform your images effortlessly.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="group relative text-center">
              <div className="overflow-hidden bg-cyan-50 p-4 rounded-xl shadow-lg cursor-pointer transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
                <img
                  src={step.image}
                  alt={step.title}
                  className="w-full h-56 object-cover rounded-lg transition-all duration-300 ease-in-out group-hover:opacity-75"
                /> 
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900">
                {step.title}
              </h3>
              <p className="text-gray-600 mt-2">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <a
            href="#"
            className="inline-block px-8 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:from-cyan-600 hover:to-blue-600"
          >
            Start Enhancing Now
          </a>
        </div>
      </div>
    </section>
  );
};

const steps = [
    {
    title: 'Upload Your Photo',
    description: 'Select the image you want to enhance from your device.',
    icon: <FaUpload />,
    image: './images/comp-img/4.jpg',
    },
    {
    title: 'Let AI Work Its Magic',
    description: 'Our AI enhances your photo instantly and accurately.',
    icon: <FaMagic />,
    image: './images/img-comp.png',
    },
    {
    title: 'Download the Result',
    description: 'Save your enhanced photo and share it with the world.',
    icon: <FaDownload />,
    image: './images/comp-img/4c.webp',
    },
];

export default HowToEnhanceSection;
