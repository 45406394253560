import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/AuthProvider';

const Header = () => {
  // State to manage mobile menu
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'text-cyan-700' : 'text-slate-700';
  };

  // Function to handle menu close on link click
  const handleMenuClick = () => {
    setIsOpen(false); // Closes the mobile menu
  };

  return (
    <>
      <div className="bg-gradient-to-r from-cyan-500 to-blue-500">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center py-2">
            <div className="flex space-x-6 mx-auto items-center">
              <span className="text-white font-semibold text-sm md:text-base">
              Transform Ordinary Photos into <span className="text-yellow-300">Extraordinary Images</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <header className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src="/images/logo.png" className="h-8 md:h-12" alt="Logo" />
              <span className="self-center text-2xl font-extrabold whitespace-nowrap dark:text-white">
                ImageEnhancer.AI
              </span>
            </Link>

            {/* Nav Links for larger screens */}
            <nav className="hidden md:flex space-x-6 items-center">
              <Link to="/" className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/')} hover:bg-slate-100 hover:text-slate-900`}>
                Home
              </Link>
              <Link to="/about" className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/about')} hover:bg-slate-100 hover:text-slate-900`}>
                About
              </Link>
              <Link to="/portfolio" className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/portfolio')} hover:bg-slate-100 hover:text-slate-900`}>
                Use Cases
              </Link>
              <Link to="/contact" className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/contact')} hover:bg-slate-100 hover:text-slate-900`}>
                Contact
              </Link>
            </nav>

            {/* Buttons for larger screens */}
            {!isAuthenticated ? <div className="hidden md:flex items-center space-x-4">
              <Link to="/signin" className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/signin')} hover:bg-slate-100 hover:text-slate-900`}>
                Signin
              </Link>
              <Link to="/signup" className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:from-blue-900 hover:to-blue-700">
                Get Started
              </Link>
            </div> : <div className="hidden md:flex items-center space-x-4">
              
              <Link to="/model-box" className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:from-blue-900 hover:to-blue-700">
                Dashboard
              </Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md hover:bg-slate-100 hover:text-slate-900`} onClick={() => logout()} >
                Logout
              </Link> 
              </div>}

            {/* Hamburger Menu for mobile */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-900 hover:text-gray-700 focus:outline-none focus:text-gray-700"
              >
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <nav className="md:hidden px-2 pt-2 pb-4 space-y-1">
            <Link to="/" className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={handleMenuClick}>
              Home
            </Link>
            <Link to="/about" className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={handleMenuClick}>
              About
            </Link>
            <Link to="/portfolio" className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={handleMenuClick}>
              Use Cases
            </Link>
            <Link to="/contact" className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={handleMenuClick}>
              Contact
            </Link>
            {!isAuthenticated ? <>
            <Link to="/signin" className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={handleMenuClick}>
              Signin
            </Link>
            <Link to="/signup" className="block max-w-32 mt-4 bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:from-blue-900 hover:to-blue-700" onClick={handleMenuClick}>
              Get Started
            </Link>
            </> : <>
            <Link className="block text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md" onClick={() => {handleMenuClick(); logout();}}>
              Logout
            </Link>
            <Link to="/model-box" className="block max-w-32 mt-4 bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:from-blue-900 hover:to-blue-700" onClick={handleMenuClick}>
              Dashboard
            </Link></> }
          </nav>
        )}
      </header>
    </>
  );
};

export default Header;
