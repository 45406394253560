import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const CallToActionSection = () => {
  return (
    <section className="relative py-20">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-6 lg:px-8">
        
        {/* Left Side: Content Section */}
        <div className="flex flex-col justify-center">
          <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight text-gray-800">
            Boost Your Image Quality with <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500"> AI-Powered Tools</span>
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            AI Image Enhancer uses advanced AI algorithms to enhance clarity, remove noise, upscale resolution, and much more—transforming your images instantly.
          </p>

          {/* Feature Highlights */}
          <ul className="space-y-4 mb-8">
            <li className="flex items-center">
              <FaCheckCircle className="text-cyan-500 w-6 h-6 mr-3" />
              <span className="text-gray-600">Enhance image clarity in seconds.</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-cyan-500 w-6 h-6 mr-3" />
              <span className="text-gray-600">Remove unwanted noise and blur with ease.</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-cyan-500 w-6 h-6 mr-3" />
              <span className="text-gray-600">Upscale resolution for print-quality images.</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-cyan-500 w-6 h-6 mr-3" />
              <span className="text-gray-600">Restore faded colors and adjust brightness.</span>
            </li>
          </ul>

          {/* Buttons */}
          <div className="flex space-x-4">
            <a
              href="#"
              className="inline-block px-6 py-3 bg-cyan-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-all"
            >
              Enhance Your Image
            </a>
            <a
              href="#"
              className="inline-block px-6 py-3 bg-white text-blue-500 border border-blue-500 font-semibold rounded-lg hover:bg-blue-50 transition-all"
            >
              Try Demo
            </a>
          </div>
        </div>

        {/* Right Side: Image or Illustration */}
        <div className="relative item-center">
          <img
            src="/images/image-comp-1.png"
            alt="AI Image Enhancer Preview"
            className="relative bg-gradient-to-b from-blue-100 to-cyan-100  p-4 rounded-xl shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
