import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const PhotoEnhancerSection = () => {
  return (
    <section className="bg-gradient-to-b from-blue-50 to-white py-12 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        {/* First Hero Section with Large Comparison */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
          <div className="rounded-xl overflow-hidden shadow-lg">
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src="./images/comp-img/8.jpg" alt="Before Image" />}
              itemTwo={<ReactCompareSliderImage src="./images/comp-img/8c.webp" alt="After Image" />}
              style={{
                borderRadius: '0.75rem',
              }}
            />
            <div className="text-center mt-2 text-gray-500">
              <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Pro-Level <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">AI Photo Enhancer</span>
            </h2>
            <p className="text-lg text-gray-600">
              Bring your images back to life with our <span className="font-semibold text-blue-500">AI-enhanced</span> tools. Remove blurs, enhance details, and make your photos pop instantly with just one click.
            </p>
            <p className="text-lg text-gray-600 mt-2">
              Whether you're working with old photos or low-quality images, our <span className="underline decoration-blue-500">AI technology</span> optimizes every pixel to deliver crystal-clear results in seconds.
            </p>
          </div>
        </div>

        {/* Second Section with Two-Column Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mb-16">
          <div className="flex flex-col justify-center">
            <h3 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Enhance Photos Without <span className="text-blue-500">Losing Quality</span>
            </h3>
            <p className="text-lg text-gray-600">
              Forget about losing quality when enhancing images. Our AI-driven solution refines your photos by preserving every detail while upgrading resolution and sharpness.
            </p>
            <p className="text-lg text-gray-600 mt-2">
              Convert pixelated, blurry photos into high-definition quality, perfect for professional presentations or personal memories.
            </p>
          </div>
          <div className="rounded-xl overflow-hidden shadow-lg">
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src="./images/comp-img/7.jpg" alt="Before Image" />}
              itemTwo={<ReactCompareSliderImage src="./images/comp-img/7c.webp" alt="After Image" />}
              style={{
                borderRadius: '0.75rem',
              }}
            />
            <div className="text-center mt-2 text-gray-500">
              <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
            </div>
          </div>
        </div>

        {/* Third Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mb-16">
          <div className="rounded-xl overflow-hidden shadow-lg">
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src="./images/comp-img/14.jpeg" alt="Before Image" />}
              itemTwo={<ReactCompareSliderImage src="./images/comp-img/14c.webp" alt="After Image" />}
              style={{
                borderRadius: '0.75rem',
              }}
            />
            <div className="text-center mt-2 text-gray-500">
              <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Transform <span className="text-cyan-500">Anime Art</span> with Precision
            </h3>
            <p className="text-lg text-gray-600">
              Elevate your anime artwork by turning low-resolution images into vibrant, high-quality masterpieces. Upscale every detail, making your favorite anime posters, wallpapers, or fan art look stunning.
            </p>
            <p className="text-lg text-gray-600 mt-2">
              Experience the magic of <span className="underline decoration-cyan-500">AI that enhances intricate lines</span> and textures without blurring or loss of quality.
            </p>
          </div>
        </div>

        {/* Fourth Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div className="flex flex-col justify-center">
            <h3 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Perfect <span className="text-blue-500">Portraits</span> Every Time
            </h3>
            <p className="text-lg text-gray-600">
              Turn mediocre portraits into professional-quality images with our AI-powered portrait enhancer. Correct lighting, reduce noise, and bring out natural beauty with a single click.
            </p>
            <p className="text-lg text-gray-600 mt-2">
              Even if your photos are underexposed or slightly blurry, our AI will intelligently adjust and sharpen them, making sure you always look your best.
            </p>
          </div>
          <div className="rounded-xl overflow-hidden shadow-lg">
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src="./images/comp-img/10.jpg" alt="Before Image" />}
              itemTwo={<ReactCompareSliderImage src="./images/comp-img/10c.webp" alt="After Image" />}
              style={{
                borderRadius: '0.75rem',
              }}
            />
            <div className="text-center mt-2 text-gray-500">
              <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PhotoEnhancerSection;
