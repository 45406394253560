import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const images = [
  {
    before: './images/comp-img/11.jpg',
    after: './images/comp-img/11c.webp',
  },
  {
    before: './images/comp-img/12.jpg',
    after: './images/comp-img/12c.webp',
  },
  {
    before: './images/comp-img/5.jpg',
    after: './images/comp-img/5c.webp',
  },
  {
    before: './images/comp-img/9.jpg',
    after: './images/comp-img/9c.webp',
  },
  {
    before: './images/comp-img/3.jpg',
    after: './images/comp-img/3c.webp',
  },
  {
    before: './images/comp-img/2.jpg',
    after: './images/comp-img/2c.webp',
  },
];

const UseCasesSection = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-6">
        {/* Heading */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">
            AI Image Enhancements: Before & After
          </h2>
          <p className="text-gray-600 mt-4 text-lg">
            Interact with the slider to see how our AI transforms your images, improving quality and clarity.
          </p>
        </div>

        {/* Use Cases Grid with ReactCompareSlider */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {images.map((image, index) => (
            <div key={index} className="rounded-xl overflow-hidden">
              <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src={image.before} alt={`Before Image ${index + 1}`} />}
                itemTwo={<ReactCompareSliderImage src={image.after} alt={`After Image ${index + 1}`} />}
                style={{
                  borderRadius: '0.75rem', // Rounded corners for a smoother look
                }}
              />
              <div className="text-center mt-2 text-gray-500">
                <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCasesSection;
