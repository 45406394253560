import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { FaStar } from 'react-icons/fa'; // For the star rating icons

const AboutUsSection = () => {
  return (
    <section className="relative py-16 md:py-24 bg-gray-50 overflow-hidden">
      {/* Background Accent */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-cyan-50 opacity-20 pointer-events-none"></div>

      <div className="relative max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 px-6 lg:px-8 items-center">
        
        {/* Left Side - Content Section */}
        <div className="md:pr-8">
          {/* Label */}
          <div className="inline-block bg-cyan-100 text-cyan-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
            About ImageEnhancer.AI
          </div>
          {/* Headline */}
          <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
            Elevate Your Photos with AI-Powered Enhancements
          </h2>
          {/* Subheadline */}
          <p className="text-lg text-gray-700 mb-6">
            At ImageEnhancer.AI, we utilize the latest AI technology to instantly enhance the quality of your photos. Whether it's improving clarity, reducing noise, upscaling resolution, or restoring faded colors, our platform is your go-to solution for breathtaking image transformations.
          </p>

          {/* Ratings and Social Proof */}
          <div className="flex items-center space-x-2 mb-6">
            <div className="flex items-center text-yellow-500">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <span className="text-gray-700 font-semibold">Rated 5 Stars by 10,000+ Users</span>
          </div>

          {/* Logos for Social Proof */}
          <div className="flex flex-wrap items-center space-x-3">
            <img src="./images/brand/1.jpg" alt="Brand 1" className="h-12 w-28 object-contain" />
            <img src="./images/brand/2.jpg" alt="Brand 2" className="h-12 w-28 object-contain" />
            <img src="./images/brand/3.jpg" alt="Brand 3" className="h-12 w-28 object-contain" />
            <img src="./images/brand/4.jpg" alt="Brand 4" className="h-12 w-28 object-contain" />
          </div>
        </div>

        {/* Right Side - Image Section */}
        <div className="relative">
          <div className="rounded-xl overflow-hidden shadow-lg">
              <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src="./images/comp-img/13.jpg" alt="Before Image" />}
                itemTwo={<ReactCompareSliderImage src="./images/comp-img/13c.webp" alt="After Image" />}
                style={{
                  borderRadius: '0.75rem',
                }}
              />
              <div className="text-center mt-2 text-gray-500">
                <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
