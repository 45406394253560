import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaQuestionCircle } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-6 border-b border-gray-200 pb-4">
      <div
        onClick={toggleFAQ}
        className="flex items-center justify-between cursor-pointer hover:text-cyan-500 transition-colors duration-300"
      >
        <div className="flex items-center">
          <FaQuestionCircle className="text-cyan-500 w-6 h-6 mr-3" />
          <h3 className="text-lg font-bold text-gray-900">{question}</h3>
        </div>
        {isOpen ? (
          <FaChevronUp className="text-cyan-500 w-5 h-5" />
        ) : (
          <FaChevronDown className="text-gray-500 w-5 h-5" />
        )}
      </div>
      {isOpen && <p className="text-gray-600 mt-4">{answer}</p>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: 'How does AI Image Enhancer work?',
      answer: `AI Image Enhancer uses advanced machine learning algorithms to analyze and enhance your photos. It can automatically detect areas that need improvement, such as sharpening blurry parts, enhancing colors, or reducing noise. This technology mimics the way professional photo editors improve image quality but does it in a fraction of the time. 
      
      With just one click, the AI processes each pixel of your image, making adjustments where needed. Whether you're enhancing an old photo or a low-resolution one, the AI is capable of upscaling the image without losing its original quality. It can even restore details in photos that seem irreparable.`,
    },
    {
      question: 'Can AI Image Enhancer improve the quality of scanned images or old photos?',
      answer: `Yes, AI Image Enhancer is especially effective at improving scanned images and old photos. These images often suffer from blurriness, noise, or faded colors, and AI Image Enhancer can restore them by:
      
      1. **Removing graininess**: Old photos or scanned images might have grain, but the AI can smooth it out while preserving details.
      2. **Restoring color vibrancy**: If the colors have faded over time, the AI tool will intelligently enhance and bring back their natural vibrancy.
      3. **Upscaling resolution**: Low-resolution images can be upscaled using AI without creating pixelation, making them suitable for printing or sharing online.
      
      It’s a perfect tool to breathe new life into your photo memories!`,
    },
    {
      question: 'Is AI Image Enhancer easy to use?',
      answer: `Absolutely! AI Image Enhancer is designed to be user-friendly, even for those with no prior experience in photo editing. All you need to do is upload your image, and with a single click, the AI does all the work for you. You don’t need to worry about adjusting settings or tweaking filters manually. 
      
      The tool is perfect for busy professionals, casual users, or anyone who needs high-quality images in a short amount of time. It’s fast, efficient, and gives you results that rival professional photo editing services.`,
    },
    {
      question: 'How long does it take to enhance an image?',
      answer: `The enhancement process is extremely fast. Once you upload your image, the AI processes it within seconds. This depends slightly on the size and resolution of the image, but for most standard photos, the process is complete in under 10 seconds. 

      The goal is to save you time while delivering high-quality results, so you can focus on sharing or using the enhanced images for your projects.`,
    },
    {
      question: 'Can I enhance multiple images at once?',
      answer: `Yes, AI Image Enhancer allows you to enhance multiple images at once through batch processing. Whether you’re working on a personal photo album, product images for an online store, or professional photography, you can upload several images at a time and let the AI handle the work in bulk.

      This feature is particularly helpful for photographers or businesses looking to enhance a large number of images quickly.`,
    },
  ];

  return (
    <section className="bg-gray-50 py-12 md:pb-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Heading */}
        <h2 className="text-3xl md:text-5xl font-extrabold text-center text-gray-900 mb-12">
          Frequently Asked Questions
        </h2>

        {/* FAQ List */}
        <div>
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
