import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const HeroSection = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-blue-50 to-blue-100 py-12 md:py-24">
      {/* Background Blobs for Modern Design */}
      <div className="absolute -left-48 bottom-0 w-32 h-32 bg-gradient-to-r from-cyan-200 to-blue-300 rounded-full opacity-50 md:w-80 md:h-80 animate-pulse"></div>
      <div className="absolute right-10 top-4 w-32 h-32 bg-gradient-to-r from-blue-300 to-indigo-400 rounded-full opacity-50 md:w-28 md:h-28 animate-pulse"></div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Text Section */}
          <div className="text-start lg:max-w-lg">
            <h1 className="text-3xl md:text-6xl font-extrabold text-gray-900">
              Enhance Your Images with AI <br />
            </h1>
            <h1 className="text-3xl md:text-6xl pt-2 pb-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
              Quality Boost in Seconds
            </h1>
            <p className="text-lg md:text-xl text-gray-700 mt-6">
              Upload any photo and let our AI-powered image enhancer instantly improve clarity, brightness, and details. Whether it's an old photo or a low-quality image, we'll make it look stunning with just one click.
            </p>

            {/* Call to Action Button */}
            <div className="mt-8">
              <a
                href="#"
                className="inline-block px-8 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:from-cyan-600 hover:to-blue-600"
              >
                Upload Image
              </a>
            </div>
          </div>

          {/* Image Comparison Slider */}
          <div className="mt-12 lg:mt-0 w-full lg:w-1/2">
            <div className="w-full h-64 md:h-96 rounded-lg overflow-hidden shadow-lg transform transition duration-500 hover:scale-105">
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    src="./images/comp-img/1.jpg"
                    srcSet="./images/comp-img/1.jpg"
                    alt="Before Image"
                    className="object-cover w-full h-full"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="./images/comp-img/1c.webp"
                    srcSet="./images/comp-img/1c.webp"
                    alt="After Image"
                    className="object-cover w-full h-full"
                  />
                }
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '0.5rem', // Tailwind's equivalent rounded-lg
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
