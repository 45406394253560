import React from 'react';
import { FaMagic, FaImage, FaSyncAlt, FaAdjust } from 'react-icons/fa'; // Example icons

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="p-6 hover:shadow-xl transition-all">
      <div className="text-cyan-500 mb-4">{icon}</div>
      <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <section className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        {/* Heading */}
        <div className="text-start mb-12">
          <h2 className="text-4xl font-extrabold text-gray-800 mb-4">
            Powerful AI Features
          </h2>
          <p className="text-xl text-gray-600">
            Discover how our AI-powered tools can enhance your photos in seconds.
          </p>
        </div>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <FeatureCard
            icon={<FaMagic size={40} />}
            title="AI Magic Enhancement"
            description="Use advanced AI to enhance the clarity and quality of your images effortlessly."
          />
          <FeatureCard
            icon={<FaImage size={40} />}
            title="Upscale Your Images"
            description="Increase the resolution of any image, making it perfect for printing or larger displays."
          />
          <FeatureCard
            icon={<FaSyncAlt size={40} />}
            title="Noise Reduction"
            description="Automatically remove noise and artifacts, bringing your photos to life with better quality."
          />
          <FeatureCard
            icon={<FaAdjust size={40} />}
            title="Color Correction"
            description="Adjust colors and brightness automatically to restore vivid, accurate color in your images."
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
