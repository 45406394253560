import React from 'react';
import CallToActionSection from './Home/Calltoactionsection';
import HeroSection from './Home/HomePageHero';
import FAQSection from './Home/FAQSection';
import HowToEnhanceSection from './Home/HowToEnhanceSection';
import PhotoEnhancerSection from './Home/PhotoEnhancerSection';
import TestimonialSection from './Home/Testimonialsection';

const Home = () => {
    return (
        <div> 
            <HeroSection />
            <HowToEnhanceSection />
            <PhotoEnhancerSection />
            <TestimonialSection />
            <FAQSection />
        </div>
    );
};

export default Home;
