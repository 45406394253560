import React, { useEffect } from 'react';
import "../../style/modal.css"

const Modelbox = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://gradio.s3-us-west-2.amazonaws.com/4.40.0/gradio.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="py-16 md:py-24">
          <div className="max-w-3xl mx-auto text-center">
            {/* Heading */}
            <h2 className="text-4xl font-extrabold text-cyan-700">
              Enhance Your Images with AI
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Upload your image and let AI enhance your photo clarity, colors, and details in seconds. Experience the next level of image transformation.
            </p>
            <p className="mt-4 text-sm text-red-300">  
              <span className="font-semibold">Note:</span> The model may take a few seconds to load. Please be patient.
            </p>
          </div> 
          <div className="relative w-full z-10 mt-12">
            <gradio-app
              src="https://rol-box-gghrt2.hf.space"
            ></gradio-app>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default Modelbox;
