import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Upper Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          
          {/* Section 1: Logo and Description */}
          <div>
            <Link to="/" className="flex items-center space-x-3 mb-4">
              <img src="/images/logo.png" className="h-12" alt="ImageEnhancer.AI Logo" />
              <span className="text-2xl font-extrabold text-white">ImageEnhancer.AI</span>
            </Link>
            <p className="text-gray-400 text-base leading-relaxed">
              Enhance your photos with cutting-edge AI technology. From image clarity to noise reduction, ImageEnhancer.AI transforms your photos into high-definition visuals effortlessly.
            </p>
          </div>

          {/* Section 2: Links */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Company Links */}
            <div>
              <h2 className="text-xl font-semibold text-white mb-4">Company</h2>
              <ul className="space-y-2">
                <li>
                  <Link to="/about" className="hover:text-cyan-500 transition-all">About Us</Link>
                </li>
                <li>
                  <Link to="/portfolio" className="hover:text-cyan-500 transition-all">Our Work</Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:text-cyan-500 transition-all">Contact</Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="hover:text-cyan-500 transition-all">Privacy Policy</Link>
                </li>
              </ul>
            </div>

            {/* Features Links */}
            <div>
              <h2 className="text-xl font-semibold text-white mb-4">Features</h2>
              <ul className="space-y-2">
                <li>
                  <Link to="/features/image-enhancement" className="hover:text-blue-500 transition-all">
                    AI Image Enhancement
                  </Link>
                </li>
                <li>
                  <Link to="/features/upscaling" className="hover:text-blue-500 transition-all">
                    Image Upscaling
                  </Link>
                </li>
                <li>
                  <Link to="/features/denoise" className="hover:text-blue-500 transition-all">
                    Noise Reduction
                  </Link>
                </li>
                <li>
                  <Link to="/features/color-enhancement" className="hover:text-blue-500 transition-all">
                    Color Enhancement
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Section 3: CTA and Social Media */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Get Started</h2>
            <p className="text-gray-400 mb-4">
              Experience the power of AI image enhancement now. Click below to enhance your first image.
            </p>
            <Link to="/model-box" className="inline-block px-6 py-2 bg-cyan-500 text-white font-semibold rounded-full hover:bg-cyan-600 transition-all mb-6">
              Enhance Now
            </Link> 
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 border-t border-gray-800 pt-6 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-500 mb-4 md:mb-0">&copy; {currentYear} ImageEnhancer.AI. All Rights Reserved.</p>
          <div className="flex space-x-4">
            <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
              <FaFacebookF size={20} />
            </a>
            <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
              <FaTwitter size={20} />
            </a>
            <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
              <FaInstagram size={20} />
            </a>
            <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
              <FaLinkedinIn size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
