import React from 'react';
import { FaStar } from 'react-icons/fa';

const TestimonialCard = ({ name, text, isHighlighted = false }) => {
  return (
    <div
      className={`p-6 rounded-lg shadow-lg transition-all duration-300 transform hover:-translate-y-1 ${
        isHighlighted
          ? 'bg-gradient-to-r from-cyan-500 to-blue-500 text-white'
          : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-cyan-100 hover:to-blue-100'
      }`}
    >
      <h4 className="text-xl font-bold mb-2">{name}</h4>
      <div className="flex items-center mb-4">
        {[...Array(5)].map((_, i) => (
          <FaStar key={i} className="text-yellow-400" />
        ))}
      </div>
      <p className="text-base">{text}</p>
    </div>
  );
};

const TestimonialSection = () => {
  return (
    <section className="bg-gray-50 text-gray-900 py-12 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

          {/* Left Section: Title and Subtitle */}
          <div className="flex flex-col justify-center mb-8 lg:mb-0">
            <h2 className="text-4xl md:text-6xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
              100K+ images enhanced
            </h2>
            <p className="text-lg md:text-xl text-gray-500">
              10,00+ people love us
            </p>
          </div>

          {/* Right Section: Testimonial Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <TestimonialCard
              name="Emily Johnson"
              text="This image enhancer is a game-changer! It has significantly improved the quality of my photos with just one click."
            />
            <TestimonialCard
              name="Michael Smith"
              text="I was amazed at how easy it was to upscale my images without losing any detail. Highly recommended!"
            />
            <TestimonialCard
              name="Sophia Lee"
              text="The AI enhancement made my old photos look like they were taken yesterday. Absolutely incredible!"
              isHighlighted={true} // Highlighted Card
            />
            <TestimonialCard
              name="David Brown"
              text="As a professional photographer, this tool has become an indispensable part of my workflow. It's fast and produces stunning results."
            />
            <TestimonialCard
              name="Olivia Garcia"
              text="I love how user-friendly this platform is. Even without technical skills, I can enhance my images effortlessly."
            />
            <TestimonialCard
              name="Daniel Martinez"
              text="The AI does an excellent job at sharpening blurred images. It saved me hours of manual editing."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
