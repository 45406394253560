import React from 'react';
import { useForm } from 'react-hook-form';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaUsers } from 'react-icons/fa';

const ContactDetails = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
    reset();
  };

  return (
    <section className="bg-gradient-to-b from-blue-50 to-white pt-16 pb-16 md:py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          
          {/* Map Section */}
          <div className="relative">
            <div className="inline-block bg-cyan-100 text-cyan-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
              Get in Touch
            </div>
            {/* Headline */}
            <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
              Let's Connect and Enhance Your Experience!
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Have questions or need assistance? We're here to help.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div className="flex items-start my-6">
                <FaMapMarkerAlt className="w-8 h-8 text-cyan-600 dark:text-cyan-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-500 dark:text-gray-200">Office location</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">16122 Collins Street, Melbourne, Australia</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaEnvelope className="w-8 h-8 text-cyan-600 dark:text-cyan-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-500 dark:text-gray-200">Send a message</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">info@genaibox.io</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">sales@genaibox.io</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaPhoneAlt className="w-8 h-8 text-cyan-600 dark:text-cyan-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-500 dark:text-gray-200">Call us directly</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-222-000</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-222-002</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaUsers className="w-8 h-8 text-cyan-600 dark:text-cyan-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-500 dark:text-gray-200">Join our team</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">hire@genaibox.io</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">hr@genaibox.io</p>
                </div>
              </div>
            </div>                      
          </div>

          {/* Contact Form Section */}
          <div className="bg-white p-8 md:p-10 rounded-lg shadow-xl">
            <div className="inline-block bg-cyan-100 text-cyan-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
              Send Us a Message
            </div>
            <h3 className="text-3xl font-semibold text-gray-900 mb-6">
              We're Here to Help
            </h3>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm text-gray-700">Name*</label>
                  <input
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    placeholder="Your Name"
                    className="w-full px-4 py-3 border rounded-lg focus:ring-cyan-500 focus:border-cyan-500"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm">
                      {errors.name.message}
                    </span>
                  )}
                </div>
                <div>
                  <label className="block text-sm text-gray-700">Email Address*</label>
                  <input
                    type="email"
                    {...register('email', { required: 'Email is required' })}
                    placeholder="Your Email"
                    className="w-full px-4 py-3 border rounded-lg focus:ring-cyan-500 focus:border-cyan-500"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm text-gray-700">Subject</label>
                <input
                  type="text"
                  {...register('subject')}
                  placeholder="Subject"
                  className="w-full px-4 py-3 border rounded-lg focus:ring-cyan-500 focus:border-cyan-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-700">Your Message*</label>
                <textarea
                  {...register('message', { required: 'Message is required' })}
                  placeholder="Write your message"
                  className="w-full h-32 px-4 py-3 border rounded-lg focus:ring-cyan-500 focus:border-cyan-500"
                ></textarea>
                {errors.message && (
                  <span className="text-red-500 text-sm">
                    {errors.message.message}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white py-3 rounded-lg hover:from-cyan-600 hover:to-blue-600 transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
