import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../Common/AuthProvider';

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { login, isAuthenticated } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const characterLimits = {
        email: 100,
        password: 20,
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1); // Redirect to the previous page if already logged in
        }
    }, [isAuthenticated]);

    // Validation Logic
    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (email.length > characterLimits.email) {
            errors.email = `Email must be less than ${characterLimits.email} characters.`;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length > characterLimits.password) {
            errors.password = `Password must be less than ${characterLimits.password} characters.`;
        }
        return errors;
    };

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
            const user = storedUsers.find((u) => u.email === email && u.password === password);

            if (user) {
                const token = Math.random().toString(36).substr(2); // Generate random token
                localStorage.setItem('authToken', token);
                localStorage.setItem('currentUser', JSON.stringify(user));
                login(token); // Call login function from AuthProvider
                navigate('/model-box'); // Redirect to homepage after successful login
            } else {
                setErrors({ general: 'Invalid email or password.' });
            }
        }
    };

    const handleGoogleSuccess = (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const googleUser = {
            email: decoded.email,
            full_name: decoded.name,
        };

        const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
        if (!existingUsers.find((u) => u.email === googleUser.email)) {
            existingUsers.push({ email: googleUser.email, password: '', full_name: googleUser.full_name });
            localStorage.setItem('users', JSON.stringify(existingUsers));
        }

        const authToken = Math.random().toString(36).substr(2); // Generate random token
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('currentUser', JSON.stringify(googleUser));
        login(authToken); // Call login function from AuthProvider
        navigate('/model-box'); // Redirect to homepage
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white py-12 md:py-24">
            <div className="grid grid-cols-1 md:grid-cols-2 max-w-4xl w-full mx-auto bg-gray-50 rounded-2xl shadow-lg overflow-hidden gap-6">
                
                {/* Left Side - Illustration */}
                <div className="hidden md:flex justify-center items-center">
                    <img
                        src="/images/auth.webp"
                        alt="AI Image Enhancement Illustration"
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Right Side - Login Form */}
                <div className="w-full p-10">
                    <div className="text-center mb-8">
                        <h2 className="text-3xl font-extrabold text-gray-900">Log in to ImageEnhancer.AI</h2>
                        <p className="text-gray-600 mt-2">
                            Or{' '}
                            <Link to="/signup" className="text-cyan-500 hover:underline">
                                create a new account
                            </Link>
                        </p>
                    </div>

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-cyan-500 ${
                                    errors.email ? 'border-red-500' : 'border-gray-300'
                                }`}
                                maxLength={characterLimits.email}
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                        </div>

                        <div className="relative">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-100 ${
                                    errors.password ? 'border-red-500' : 'border-gray-300'
                                } focus:outline-none focus:ring-2 focus:ring-cyan-500`}
                                maxLength={characterLimits.password}
                            />
                            <span
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center cursor-pointer"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-cyan-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full py-3 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-semibold hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-cyan-500"
                        >
                            Log in
                        </button>
                    </form>

                    <div className="text-center mt-6">
                        <p className="text-gray-500 font-semibold">OR</p>
                    </div>

                    <div className="w-full flex justify-center mt-6">
                        <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onFailure={handleGoogleFailure}
                                render={(renderProps) => (
                                    <button
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        className="w-full flex items-center justify-center py-3 px-4 border rounded-lg bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                                    >
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png"
                                            alt="Google Login"
                                            className="w-6 h-6 mr-2"
                                        />
                                        <span className="text-gray-700">Sign in with Google</span>
                                    </button>
                                )}
                            />
                        </GoogleOAuthProvider>
                    </div>

                    {errors.general && <p className="text-red-500 mt-4 text-sm">{errors.general}</p>}
                </div>
            </div>
        </div>
    );
};

export default SigninPage;
